import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { actions as authActions, selectors as authSelectors } from '../../../auth';
import { actions as coreActions, selectors as coreSelectors, translate, Alert } from '../../../core';
import * as actions from '../../actions';
import { STATE_KEYS } from '../../constants';
import { updateProfileValue, validateProfileValues } from '../../services';
import {
    getCompanyFeatures,
    isLanguageChanged,
    isLoadingPersonalSettings,
    isSavingPersonalSettings
} from '../../selectors';
import { appFonts, baseColors, spacing } from '../../../../styles';

const PREFERRED_NAME_ENABLE = 'preferred_name_enable';
const ORIGINAL_FIRST_NAME = 'originalFirstName';
const FIELDS = {
    country: 'country',
    region: 'region',
    location: 'location',
    department: 'department',
};
const USER_NAME_FIELDS = {
    firstName: 'firstName',
    lastName: 'lastName',
};

const getCurrentLanguage = (currentLanguageId, languages) => {
    if (!languages) return null;
    const langCode = _.find(Object.keys(languages), el => el === currentLanguageId);
    return { label: languages[langCode], id: langCode };
};

export default function WithAccountSettingsBase(WrappedComponent) {
    class WithAccountSettingsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            hasUnsavedChanges: PropTypes.func,
            isLoading: PropTypes.bool,
            isSaving: PropTypes.bool,
            currentLanguageId: PropTypes.string,
            languages: PropTypes.array.isRequired,
            isLanguageChanged: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            profileFields: PropTypes.array,
            isEnabledHRIS: PropTypes.bool,
            companyFeatures: PropTypes.object,
            isTelus: PropTypes.bool,
            isLocationDataPrefilled: PropTypes.bool,
            isEnabledHRISCurrentUser: PropTypes.bool,
            isLocationDataReadOnly: PropTypes.bool
        };

        static defaultProps = {
            hasUnsavedChanges: null,
            isLoading: false,
            isSaving: false,
            currentLanguageId: '',
            isLanguageChanged: false,
            profileFields: [],
            isEnabledHRIS: false,
            companyFeatures: {},
            isTelus: false,
            isLocationDataPrefilled: false,
            isEnabledHRISCurrentUser: false,
            isLocationDataReadOnly: false
        };

        static getDerivedStateFromProps(nextProps, prevState) {
            const state = { isLoadingOrSaving: nextProps.isLoadingOrSaving, isLoadingUser: nextProps.isLoadingUser };
            let { profileFields } = nextProps;

            if ((prevState.isLoadingUser && !nextProps.isLoadingUser) || (prevState.isLoadingOrSaving && !nextProps.isLoadingOrSaving)) {
                if (_.find(nextProps.companyFeatures, o => o.slug === PREFERRED_NAME_ENABLE && o.enabled === false)) {
                    profileFields = _.filter(nextProps.profileFields, o => o.stateKey !== STATE_KEYS.preferredName);
                }
                return {
                    ...state,
                    language: getCurrentLanguage(nextProps.currentLanguageId, nextProps.languages),
                    profileFields
                };
            }
            return state;
        }

        constructor(props) {
            super(props);
            const language = props.isLanguageChanged ? null : getCurrentLanguage(props.currentLanguageId, props.languages);

            this.state = {
                language,
                profileFields: props.profileFields,
                errors: {},
                email: '',
            };
            this.props.actions.getUserSettings();
        }


        onLanguageChange = language => {
            this.setState(() => ({ language: getCurrentLanguage(language, this.props.languages), isLanguageChanged: true }));
        };

        trackLanguageChanges = hasChanges => this.props.hasUnsavedChanges && this.props.hasUnsavedChanges(hasChanges);

        saveLanguageChanges = () => {
            this.trackLanguageChanges(false);
            if (this.state.isLanguageChanged) {
                this.props.actions.changeLanguage(this.state.language.id);
                this.props.actions.getUserSettings();
            }
            return true;
        };

        generalSubmit = () => {
            const errors = { ...validateProfileValues(this.state.profileFields), ...this.submitNotFilled(), ...this.submitUserName() };
            this.setState(() => ({ errors }));
            if (!_.isEmpty(errors)) {
                this.props.actions.validateProfileDataError();
            } else {
                if (!this.currentLocation && _.has(this, 'wrapped.goBack')) this.wrapped.goBack();
                this.submitFilled();
                const params = {};
                this.props.hasUnsavedChanges && this.props.hasUnsavedChanges(false);
                this.state.profileFields.forEach(field => {
                    const key = field.stateKey === STATE_KEYS.firstName ? ORIGINAL_FIRST_NAME : field.stateKey;
                    if (key && this.props.profileFields[key] !== field.value && !field.readOnly) {
                        params[field.stateKey] = field.value;
                    }
                });
                this.props.authActions.saveUser(params);
                return true;
            }
        };

        updateUserValue = item => text => {
            const profileFields = updateProfileValue(this.state.profileFields, item.stateKey, text);
            this.props.hasUnsavedChanges && this.props.hasUnsavedChanges(true);
            this.setState(() => ({ profileFields }));
        };

        deleteAccount = () => {
            if (this.state.email.toLowerCase() === this.props.email.toLowerCase()) {
                this.props.actions.deleteAccount();
            } else {
                Alert.alert('', this.props.i18n.t('resetPasswordInvalidValue'));
            }
        };

        get isHRIS() {
            return this.props.isEnabledHRIS;
        }

        get nameProfileFields() {
            const nameProfileFields = this.state.profileFields.concat();
            return nameProfileFields.filter(item => item.name !== 'preferredNameOptional');
        }

        get preferredNameField() {
            const preferredNameField = this.state.profileFields.concat();
            return preferredNameField.filter(item => item.name === 'preferredNameOptional')[0];
        }

        get isFilled() {
            return ((_.get(this.currentLocation, 'isCorporateLocation') && _.get(this.currentLocation, 'department.id')) ||
                (!_.get(this.currentLocation, 'isCorporateLocation') && _.get(this.currentLocation, 'location.id')));
        }

        get isFirstLoading() {
            return this.props.isUpdatingUser || this.props.isLoadingLocations || this.props.isLoadingUserLocations;
        }

        get subtitle() {
            return this.props.isCorporateLocation ?
                this.props.i18n.t('Work location') :
                this.props.i18n.t('location');
        }

        get deleteAccountTitle() {
            return this.props.i18n.t('deleteAccount');
        }

        get deleteAccountText() {
            const { i18n, isTelus } = this.props;
            const appName = isTelus ? i18n.t('appStoreNameTelus') : i18n.t('appStoreNameSprout');

            return i18n.t('deleteAccountTextAppName', { appName });
        }

        get deleteAccountButtonText() {
            return this.props.i18n.t('deleteAccountButtonTitle');
        }

        onEmailChange = email => {
            this.setState({ email });
        };

        getErrorByField = field => {
            switch (field) {
                case FIELDS.department: return this.props.i18n.t('edit_profile_department_error');
                case FIELDS.location: return this.props.i18n.t('edit_profile_location_error');
                case USER_NAME_FIELDS.firstName: return this.props.i18n.t('settingsErrorMessage.firstName');
                case USER_NAME_FIELDS.lastName: return this.props.i18n.t('settingsErrorMessage.lastName');
                default: return this.props.i18n.t('edit_profile_unspecified_value');
            }
        };

        submitFilled = () => {
            if (this.props.isLocationDataReadOnly && this.props.isEnabledHRISCurrentUser) return true;

            const { location_name, location_id } = _.get(this.currentLocation, 'location');
            this.props.hasUnsavedChanges && this.props.hasUnsavedChanges(false);
            if (!_.get(this.currentLocation, 'isCorporateLocation')) {
                if (_.find(this.props.userLocations, l => location_id === l.location_id)) {
                    this.props.actions.updateUserLocation(
                        { location_name, location_id });
                } else {
                    this.props.actions.setUserLocation(
                        { location_name, location_id }, true);
                }
            }
            else {
                const { department_name, department_id } = _.get(this.currentLocation, 'department');
                if (_.find(this.props.userLocations, l => location_id === l.location_id)) {
                    this.props.actions.updateUserLocationDepartment(
                        { location_name, location_id }, { department_name, department_id });
                } else {
                    this.props.actions.setUserLocationDepartment(
                        { location_name, location_id }, { department_name, department_id }, true);
                }
            }
            return true;
        };

        submitNotFilled = () => {
            if (this.props.isLocationDataReadOnly && this.props.isEnabledHRISCurrentUser) return {};
            const errors = {};
            _.forEach(FIELDS, field => {
                if (!_.get(this.currentLocation, [field, 'id'])) {
                    if ((field === 'department' && _.get(this.currentLocation, 'isCorporateLocation')) || field !== 'department') {
                        errors[field] = this.getErrorByField(field);
                    }
                }
            });
            return errors;
        };

        submitUserName = () => {
            const errors ={};
            _.forEach(USER_NAME_FIELDS, field => {
                const inputItem = _.find(this.nameProfileFields, nameField => nameField.stateKey === field);
                if (!inputItem.value) {
                    errors[field] = this.getErrorByField(field);
                }
            });

            return errors;
        }

        changeLocation = currentLocation => {
            this.currentLocation = currentLocation;
            this.setState({ errors: {} });
        };

        saveRef = ref => (this.wrapped = ref);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    saveLanguageChanges={this.saveLanguageChanges}
                    onLanguageChange={this.onLanguageChange}
                    personalFields={this.state.personalFields}
                    currentLanguage={this.state.language}
                    updateUserValue={this.updateUserValue}
                    profileFields={this.state.profileFields}
                    errors={this.state.errors}
                    isHRIS={this.isHRIS}
                    preferredNameField={this.preferredNameField}
                    nameProfileFields={this.nameProfileFields}
                    ref={this.saveRef}
                    changeLocation={this.changeLocation}
                    isLoading={this.isFirstLoading}
                    getErrorByField={this.getErrorByField}
                    generalSubmit={this.generalSubmit}
                    subtitle={this.subtitle}
                    deleteAccountTitle={this.deleteAccountTitle}
                    deleteAccountText={this.deleteAccountText}
                    deleteAccountButtonText={this.deleteAccountButtonText}
                    onEmailChange={this.onEmailChange}
                    deleteAccount={this.deleteAccount}
                    email={this.state.email}
                />
            );
        }
    }

    const mapStateToProps = state => {
        const currentUser = coreSelectors.getCurrentUser(state);
        const isLoading = authSelectors.isLoadingUser(state) || isLoadingPersonalSettings(state);
        const isSaving = authSelectors.isSavingUser(state) || isSavingPersonalSettings(state);

        return {
            email: coreSelectors.getCurrentUser(state).email,
            profileFields: authSelectors.getProfileFields(state),
            isLoading,
            isSaving,
            isLoadingOrSaving: isLoading || isSaving,
            isEnabledHRIS: authSelectors.isEnabledHRIS(state),
            companyFeatures: getCompanyFeatures(state),
            languages: authSelectors.getCompanySupportedLanguages(state),
            currentLanguageId: authSelectors.getCurrentUserLanguage(state),
            userEmail: _.get(currentUser, 'email', ''),
            isLanguageChanged: isLanguageChanged(state),
            isUpdatingUser: authSelectors.isUpdatingUser(state),
            isLoadingUser: authSelectors.isLoadingCurrentUser(state),
            isTelus: coreSelectors.isTelus(state),
            isLocationDataPrefilled: authSelectors.isDataPrefilled(state),
            isEnabledHRISCurrentUser: coreSelectors.isEnabledHRISCurrentUser(state),
            isLocationDataReadOnly: authSelectors.isLocationDataReadOnly(state)
        };
    };

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators({ ...actions, ...coreActions, ...authActions }, dispatch),
        coreActions: bindActionCreators(coreActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(WithAccountSettingsBase));
}

export const styles = {
    deleteAccountButton: {
        backgroundColor: baseColors.dangerDarker,
        color: baseColors.white,
        marginTop: spacing.s5,
        borderRadius: spacing.s5,
    },
    divider: {
        backgroundColor: '#D3D3D3',
        height: 1,
    },
    deleteAccountSectionTitle: {
        ...appFonts.lgBold,
        color: baseColors.black,
        marginBottom: spacing.s1
    },
    deleteAccountSectionText: {
        ...appFonts.mdRegular,
        color: baseColors.grey40
    }
};