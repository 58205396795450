import { constants as coreConstants } from '../core';

export const NAME = 'auth';
export const NOT_PERSISTED = true;
export const DELAY_RENDER_TIME = 500;

export const LOGIN_STATUSES = Object.freeze({
    loggedIn: 'loggedIn',
    loggedOut: 'loggedOut',
    stateReset: 'stateReset'
});

export const EXTERNAL_SERVICES = Object.freeze({
    facebook: 'Facebook',
    google: 'Google',
    medibank: 'MyMedibank',
    saml: 'samlSSO',
    corporate: 'corporate',
    apple: 'Apple'
});

// currently only medibank is supported
export const EXTERNAL_SERVICES_NO_MODAL = [EXTERNAL_SERVICES.medibank];

export const CORPORATE_ACCOUNT_LINK = Object({
    accountLinkSuccessStatus: 'success',
    accountLinkFailedStatus: 'failed',
    corporateAccountLink: 'corporateAccountLink',
    corporateAccountLinkSuccess: 'corporateAccountLinkSuccess',
    corporateAccountLinkError: 'corporateAccountLinkError',

});
export const SSO_CONTEXT = Object({
    signUp: 'signUpSSO',
    signIn: 'signInSSO',
    accountLink: 'profileSSOAccountLink',
    accountLinkFailure: 'profileSSOAccountLinkFailed',
    rewardsAuthentication: 'ssoRewardAuthentication',
    rewardsReauthentication: 'ssoRewardReauthentication'
});

export const SSO_ERROR_CODES = Object({
    EmailNotVerified: 'auth.sso.emailNotVerified',
});

export const REGISTRATION_STEPS = Object.freeze({
    programDomain: 'programDomain',
    findProgramDomain: 'findProgramDomain',
    register: 'register',
    signUpSelection: 'signUpSelection',
    partnerCustomSSO: 'partnerCustomSSO',
    corporatePin: 'corporatePin',
    location: 'location',
    disclaimer: 'disclaimer',
    verify: 'verify',
    autoLogin: 'autoLogin',
    saml: EXTERNAL_SERVICES.saml,
    verifyCorporateLink: 'verifyCorporateLink',
    employeeIDVerification: 'employeeIDVerification',
    thvcSSO: 'thvcSSO',
    thvcSignUpSelection: 'thvcSignUpSelection',
    HRISCountrySelection: 'HRISCountrySelection',
    hybridLoginSelection: 'hybridLoginSelection'
});

export const REGISTRATION_ITEM_IDS = Object.freeze({
    email: 'email',
    corporate: 'corporate',
    facebook: EXTERNAL_SERVICES.facebook,
    google: EXTERNAL_SERVICES.google,
    findProgramDomain: REGISTRATION_STEPS.findProgramDomain,
    samlError: 'samlError',
    autoLogin: REGISTRATION_STEPS.autoLogin,
    saml: EXTERNAL_SERVICES.saml,
    register: REGISTRATION_STEPS.register,
    apple: EXTERNAL_SERVICES.apple
});

export const USER_REGISTRATION_STEPS = Object.freeze({
    location: 'location',
    verify: 'verify',
    disclaimer: 'disclaimer',
    register: 'register',
    none: ''
});

export const USER_REGISTRATION_STATUS = Object.freeze({
    notFound: 'notFound', // email not found
    notRegistered: 'notRegistered', // email exists but not registered, HRIS sign up
    notRegisteredSSO: 'notRegisteredSSO', // email exists but not registered, SSO sign up
    registered: 'registered', // email exists and registered, normal password login
    registeredSSO: 'registeredSSO', // email exists and registered, SSO login
    registeredTHVC: 'registeredTHVC', // email exists and registered, THVC login,
    hybridLogin: 'hybridLogin' // For Moda: user needs to select between using SSO or logging in with password
});

export const SAML_SSO_STATUSES = Object.freeze({
    login: 'login',
    registration: 'registration',
    authentication_failed: 'authentication_failed'
});

export const LOGIN_STEPS = Object.freeze({
    programDomain: REGISTRATION_STEPS.programDomain,
    findProgramDomain: REGISTRATION_STEPS.findProgramDomain,
    saml: REGISTRATION_STEPS.saml,
    autoLogin: REGISTRATION_STEPS.autoLogin,
    corporatePin: REGISTRATION_STEPS.corporatePin,
    signIn: 'signIn',
    signInSelection: 'signInSelection',
    register: REGISTRATION_STEPS.register,
    signInEmail: 'signInEmail',
    signInPassword: 'signInPassword',
    hybridLoginSelection: 'hybridLoginSelection'
});

export const LOGIN_ITEM_IDS = Object.freeze({
    facebook: REGISTRATION_ITEM_IDS.facebook,
    google: REGISTRATION_ITEM_IDS.google,
    findProgramDomain: REGISTRATION_ITEM_IDS.findProgramDomain,
    samlError: REGISTRATION_ITEM_IDS.samlError,
    autoLogin: REGISTRATION_ITEM_IDS.autoLogin,
    advanced: 'advanced',
    saml: REGISTRATION_ITEM_IDS.saml,
    signin: LOGIN_STEPS.signIn,
    register: REGISTRATION_STEPS.register,
    apple: REGISTRATION_ITEM_IDS.apple,
    hybridLoginSelection: REGISTRATION_STEPS.hybridLoginSelection
});

export const POLICY_FIELD_NAME = 'policyAgree';
export const POLICY_FIELD = { name: POLICY_FIELD_NAME, enabled: true, readonly: false };

export const DEFAULT_FIELDS = [
    { name: 'firstName', enabled: true, readonly: false },
    { name: 'lastName', enabled: true, readonly: false },
    { name: 'email', enabled: true, readonly: false },
    { name: 'password', enabled: true, readonly: false },
    POLICY_FIELD
];

export const POSSIBLE_TYPES = {
    text: 'text',
    password: 'password',
    bool: 'bool'
};

export const FIELDS_CONFIG = isLiveBetter => ({
    firstName: { order: 1, type: POSSIBLE_TYPES.text, defaultValue: '', autoCapitalize: 'words', forcedEditable: true, keyboardType: isLiveBetter && !coreConstants.isAndroid ? 'ascii-capable' : 'default' },
    lastName: { order: 2, type: POSSIBLE_TYPES.text, defaultValue: '', autoCapitalize: 'words', forcedEditable: true, keyboardType: isLiveBetter && !coreConstants.isAndroid? 'ascii-capable' : 'default' },
    employeeId: { order: 3, type: POSSIBLE_TYPES.text, defaultValue: '' },
    email: { order: 4, type: POSSIBLE_TYPES.text, defaultValue: '' },
    password: { order: 5, type: POSSIBLE_TYPES.password, defaultValue: '' },
    allowMarketingComms: { type: POSSIBLE_TYPES.bool, defaultValue: isLiveBetter },
    allowPartnerMarketingComms: { type: POSSIBLE_TYPES.bool, defaultValue: isLiveBetter },
    [POLICY_FIELD_NAME]: { type: POSSIBLE_TYPES.bool, defaultValue: false }
});

export const BUILD_EXTENSION_FIELDS = {
    [coreConstants.BUILDS.mylivebetter]: {
        firstName: { keyboardType: coreConstants.isAndroid ? 'default' : 'ascii-capable' },
        lastName: { keyboardType: coreConstants.isAndroid ? 'default' : 'ascii-capable' },
        allowMarketingComms: { defaultValue: true },
        allowPartnerMarketingComms: { defaultValue: true },
    }
};

export const APPLE_SIGN_IN_TYPES = Object.freeze({
    signIn: 'signIn',
    continue: 'continue',
    signUp: 'signUp'
});

export const CORPORATE_LINK_CONTEXT = 'CorporateAccountLinkFlow';

export const MYMEDIBANK_AGE_ERROR = 'invalid_date_value';
export const EMPLOYEE_ID = 'employee_id';
export const EMAIL_NOT_FOUND = 'email_not_found';
export const USER_PROGRAM_NAME = 'userProgramName';
export const HAS_SSO_URL = 'has_sso_url';

export const SMART_MODE_PROPERTY = 'SMART_MODE';

export const TELUS_SSO_TYPE = 'thvc';

export const TELUS_KRABAPPEL_URL = 'https://virtualcare.telushealth.com/krabappel/new_signup/email';

const isMobileApp = '?mobile=1';
const TELUS_LOGIN = 'https://signin.wellness.virtualcare.telushealth.com/lovejoy';
const TELUS_TEST_LOGIN = 'https://signin.wellness.sandbox.virtualcare.telushealth.com/lovejoy';

export const TELUS_LOGIN_URL_MOBILE = `${TELUS_LOGIN}${isMobileApp}`;
export const TELUS_TEST_LOGIN_URL_MOBILE = `${TELUS_TEST_LOGIN}${isMobileApp}`;
export const TELUS_LOGIN_URL_WEB = `${TELUS_LOGIN}`;
export const TELUS_TEST_LOGIN_URL_WEB = `${TELUS_TEST_LOGIN}`;

export const TELUS_CHANGE_PASSWORD_URL = 'https://virtualcare.telushealth.com/lovejoy/forgot_password?origin=https%3A%2F%2Fvirtualcare.telushealth.com%2Fszyslak%2Fhome';

export const PRIVACY_POLICY = 'privacyPolicy';
export const TERMS_OF_SERVICE = 'termsOfService';

// export const HRIS_INTAKE_NO_EMAIL = 'no_email';
// export const HRIS_INTAKE_MULTI = 'multi_type';

export const HRIS_INTAKE_TYPE = Object.freeze({
    noEmail: 'no_email',
    multi: 'multi_type'
});

export const HRIS_ID_STATUS = Object.freeze({
    exists: 'exists',
    doesnot_exist: 'doesnot_exist'
});

export const LOCATION_LABELS = Object.freeze({
    country: 'countries_label',
    city: 'cities_label',
    department: 'departments_label',
    location: 'locations_label',
    region: 'regions_label'
});

export const BELL_SUBDOMAIN = 'bell';
export const BELL_SUBDOMAIN_DEMO = 'belldemo';
export const BELL_SUBDOMAIN_BETA = 'bellbeta';

export const RBC_SUBDOMAIN = 'rbc';
export const RBC_SUBDOMAIN_LOA = 'rbcloa';
