import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';

import { baseColors, layoutStyle, importantClass } from '../../../../styles';
import { components as Core, ROUTES } from '../../../core';
import { TOUCHABLE_TYPES, TRANSACTION_TYPES } from '../../constants';
import RewardTouchableItem from '../RewardTouchableItem';
import WithRewardsDetailsBase, {
    styles as baseStyles,
    HELP_ICON_MARGIN_LEFT,
    HELP_ICON_SIZE
} from './RewardsDetailsBase';
import DashboardShopPointsItem from '../Dashboard/DashboardShopPointsItem';

class RewardsDetails extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        transactions: PropTypes.array.isRequired,
        scrollableTabs: PropTypes.array.isRequired,
        tabs: PropTypes.object.isRequired,
        activeTab: PropTypes.object.isRequired,
        onPressYTD: PropTypes.func.isRequired,
        rewardsCurrencyName: PropTypes.string.isRequired,
        numRedeemable: PropTypes.number.isRequired,
        earnPotential: PropTypes.number.isRequired,
        numRemaining: PropTypes.number.isRequired,
        i18n: PropTypes.object.isRequired,
        onTabPress: PropTypes.func.isRequired,
        headerSubtitle: PropTypes.string.isRequired,
        items: PropTypes.array.isRequired,
        isLoading: PropTypes.bool,
        numMissed: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        shouldShowBalanceHistoryValues: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isLoading: false,
    };

    constructor(props) {
        super(props);
        this.LIST_ITEM_PROPS = {
            separator: true,
            mainContainerClassName: css(styles.itemContainer)
        };
    }

    get emptyListMessage() {
        const { activeTab } = this.props;
        return (
            <Core.EmptyListSimple
                message={activeTab.emptyMessage}
                className={css(styles.emptyList)}
            />
        );
    }

    get items() {
        return (
            <div>
                {this.itemsToRender}
            </div>
        );
    }

    get itemsToRender() {
        const { items, isLoading } = this.props;
        if (items.length > 0) {
            return _.map(items, this.renderItem);
        } else if (!items.length && !isLoading) {
            return this.emptyListMessage;
        }
        return null;
    }

    get sections() {
        return (
            <div>
                {this.tabItems}
                {this.items}
            </div>
        );
    }

    get dashboardShopPointsItem() {
        return <DashboardShopPointsItem hasShoppingCart={false} hasRedirection={false} />;
    }

    get headerComponent() {
        const { i18n, onPressYTD, earnPotential, numRemaining, headerSubtitle,
            numMissed, title, shouldShowBalanceHistoryValues } = this.props;
        return [
            <Core.EntityDetailsHeader
                hasBackButton={true}
                title={title}
            />,
            <Core.SubHeader
                title={title}
                noTopPadding={true}
                right={this.dashboardShopPointsItem}
            />,
            <>
                {shouldShowBalanceHistoryValues ?
                    <div className={css(styles.subHeader, layoutStyle.flex)}>
                        <div className={css(styles.subHeaderItem, layoutStyle.flexColumn)}>
                            <div className={css(styles.subHeaderTitle)}>{i18n.t('potential')}</div>
                            <div className={css(styles.subHeaderValue)}>{earnPotential}</div>
                        </div>
                        <div className={css(styles.subHeaderItem, layoutStyle.flexColumn)}>
                            <div className={css(styles.subHeaderTitle)}>{headerSubtitle}</div>
                            <div className={css(styles.subHeaderValue)}>{numRemaining}</div>
                        </div>
                        <Core.Button
                            onPress={onPressYTD}
                            type="text"
                            className={css(styles.subHeaderItem, layoutStyle.flexColumn)}
                            size={Core.Button.SIZES.medium}>
                            <div className={css(layoutStyle.flexColumnCenter)}>
                                <div className={css(layoutStyle.flex, styles.subHeaderMissedWeb)}>
                                    <div className={css(styles.subHeaderTitle)}>{i18n.t('missed')}</div>
                                    <Core.Icon
                                        name="question-circle"
                                        type="fa"
                                        size={HELP_ICON_SIZE}
                                        color={baseColors.grey50}
                                        className={css(styles.subHeaderHelpIcon)}
                                    />
                                </div>
                                <div className={css(styles.subHeaderValue)}>{numMissed}</div>
                            </div>
                        </Core.Button>
                    </div>
                    : null}
            </>
        ];
    }

    openAchievementDetails = achievementId => {
        this.props.history.push(ROUTES.achievementDetails(achievementId));
    };

    openOrderDetails = orderId => {
        this.props.history.push(ROUTES.orderDetails(orderId));
    };

    openChallengeRewardDetails = reward => {
        const { linkId: challengeId, description: challengeName, points: rewardAmount, timestampIssued: rewardDate, type: rewardType } = reward;
        this.props.history.push(
            ROUTES.challengeRewardDetails(challengeId),
            { challengeName, rewardAmount, rewardDate, rewardType }
        );
    };

    goToTransactionDetails = item => {
        switch (item.type) {
            case TRANSACTION_TYPES.REDEMPTION:
                this.openOrderDetails(item.linkId);
                break;
            case TRANSACTION_TYPES.REWARD:
                this.openAchievementDetails(item.linkId);
                break;
            case TRANSACTION_TYPES.CHALLENGE:
            case TRANSACTION_TYPES.GOAL:
                this.openChallengeRewardDetails(item);
                break;
            default:
                break;
        }
    };

    goToOrderDetails = item => {
        this.openOrderDetails(item.id);
    };

    renderItem = item => {
        let passProps;
        switch (this.props.activeTab.id) {
            case this.props.tabs.TRANSACTION_LOG.id:
                passProps = {
                    type: TOUCHABLE_TYPES.TRANSACTION,
                    onPressSingle: this.goToTransactionDetails
                };
                break;
            case this.props.tabs.ORDER_HISTORY.id:
                passProps = {
                    type: TOUCHABLE_TYPES.ORDER,
                    onPressSingle: this.goToOrderDetails
                };
                break;
            default:
                return null;
        }
        return <RewardTouchableItem key={item.id} item={item} {...passProps} {...this.LIST_ITEM_PROPS} />;
    };

    render() {
        return (
            <Core.Layout.WiderContainer>
                {this.headerComponent}
                <Core.TabsWithContent
                    onChange={this.props.onTabPress}
                    tabs={this.props.scrollableTabs}
                    activeTab={this.props.activeTab}
                    tabClassName={css(styles.tabsContainer)}
                    isStickyUnderNavBar={true}>
                    {this.sections}
                </Core.TabsWithContent>
                {this.props.isLoading ? <Core.ListLoading /> : null}
            </Core.Layout.WiderContainer>
        );
    }
}

export default WithRewardsDetailsBase(RewardsDetails);

const styles = StyleSheet.create({
    ...baseStyles,
    subHeaderItem: importantClass({
        ...baseStyles.subHeaderItem
    }),
    subHeaderMissedWeb: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    subHeaderHelpIcon: {
        marginLeft: HELP_ICON_MARGIN_LEFT
    },
});
