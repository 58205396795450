import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate, parsers, selectors as coreSelectors } from '../../../core';
import { getChallenge } from '../../selectors';
import { appFonts, baseColors, exploreImageHeight, spacing } from '../../../../styles';
import FeaturedTag from '../../../core/components/FeaturedTag';
import { challengeDateText, isTeamChallenge } from '../../services/helper';
import JoinedTag from '../../../core/components/JoinedTag';

export default function WithExploreCarouselItemBase(WrappedComponent) {
    class ExploreCarouselItemBase extends PureComponent {
        static propTypes = {
            challenge: PropTypes.object.isRequired
        };

        static defaultProps = {};

        get challengeImage() {
            return this.props.challenge.challengeImageURL || this.props.challenge.imageUrl;
        }

        get challengeName() {
            return this.props.challenge.goalName || this.props.challenge.challengeName;
        }

        get challengeInstruction() {
            const instruction = this.props.challenge.instruction || this.props.challenge.challengeInstruction;
            return instruction ? parsers.replaceAllBr(instruction) : null;
        }

        get renderFeaturedTag() {
            const { i18n, challenge } = this.props;
            const text = i18n.t('promoted');
            return <FeaturedTag showTag={+challenge.isFeatured} text={text} />;
        }

        get renderJoinedTag() {
            return <JoinedTag showTag={this.props.challenge.isMember} />;
        }

        get challengeDateText() {
            return challengeDateText(this.props.challenge);
        }

        get isTeamChallenge() {
            return isTeamChallenge(this.props.challenge);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    challengeImage={this.challengeImage}
                    challengeName={this.challengeName}
                    challengeInstruction={this.challengeInstruction}
                    featuredTag={this.renderFeaturedTag}
                    challengeDateText={this.challengeDateText}
                    joinedTag={this.renderJoinedTag}
                    isTeamChallenge={this.isTeamChallenge}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId),
        pointsUnit: coreSelectors.getCustomPointsUnit(state),
    });

    return connect(mapStateToProps)(translate()(ExploreCarouselItemBase));
}

export const styles = {
    communityTitle: {
        backgroundColor: 'transparent',
        color: baseColors.black,
        ...appFonts.lgBold
    },
    communityDetails: {
        color: baseColors.grey40,
        ...appFonts.xsRegular,
        marginTop: spacing.s0,
    },
    infoContainer: {
        marginTop: spacing.s2
    },
    exploreHeightImage: {
        height: exploreImageHeight
    },
    imageContainer: {
        borderRadius: spacing.s1,
        overflow: 'hidden',
        height: '100%'
    },
    bonusMarkContainer: {
        marginTop: spacing.s1,
    },
};
