import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { getAppVersion, translate } from '../../../core';

import frowningFace from '../../../../image/feedback_smileys/Frowning_face.png';
import grinningFace from '../../../../image/feedback_smileys/Grinning_face.png';
import neutralFace from '../../../../image/feedback_smileys/Neutral_face.png';
import { actions as meActions } from '../../index';
import { isLoadingSendFeedbackOnHealthScore } from '../../selectors';

export default function HealthScoreFeedbackModalBase(WrappedComponent) {
    class HealthScoreFeedbackModalBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            closeModal: PropTypes.func.isRequired,
            isLoading: PropTypes.bool.isRequired
        };

        constructor(props) {
            super(props);

            this.state = {
                isEmojiSectionVisible: true,
                isCommentSectionVisible: false,
                isThankYouModalVisible: false,
                userComment: '',
                emojies: {
                    frowningFace: {
                        src: frowningFace,
                        rating: '1',
                        label: 'frowningFace'
                    },
                    neutralFace: {
                        src: neutralFace,
                        rating: '3',
                        label: 'neutralFace'
                    },
                    grinningFace: {
                        src: grinningFace,
                        rating: '5',
                        label: 'grinningFace'
                    }
                },
                chosenRating: ''
            };
        }

        get emojiSectionText() {
            return this.props.i18n.t('healthScoreFeedbackModal.emojiSection');
        }

        get commentSectionText() {
            return this.props.i18n.t('healthScoreFeedbackModal.commentSectionText');
        }

        get commentSectionPlaceholder() {
            return this.props.i18n.t('healthScoreFeedbackModal.commentSectionPlaceholder');
        }

        get thankYouModalText() {
            return this.props.i18n.t('healthScoreFeedbackModal.thankYouModal');
        }

        get submitBtnText() {
            return this.props.i18n.t('submit');
        }

        get closeBtnText() {
            return this.props.i18n.t('close');
        }

        get spaceInEmojiBlock() {
            const { isCommentSectionVisible } = this.state;

            return isCommentSectionVisible ? 'height24px' : 'height36px';
        }

        setNewUserComment = newComment => {
            this.setState({ userComment: newComment });
        }

        setChosenRating = newRating => {
            this.setState({ chosenRating: newRating, isCommentSectionVisible: true });
        }

        submitForm = deviceModel => {
            const { actions } = this.props;
            const { chosenRating, userComment } = this.state;
            const appVersion = getAppVersion();

            const params = {
                rating: chosenRating,
                feedback_slug: 'health_score_feedback',
                comments: userComment,
                app_version: appVersion,
                device: deviceModel,
                metadata: ''
            };

            actions.sendHealthScoreFeedback(params, this.showThankYouModal);
        }

        showThankYouModal = () => {
            this.setState({
                isCommentSectionVisible: false,
                isEmojiSectionVisible: false,
                isThankYouModalVisible: true,
            });
        }

        render() {
            const { isLoading } = this.props;

            return (
                <WrappedComponent
                    {...this.props}
                    emojiSectionText={this.emojiSectionText}
                    commentSectionText={this.commentSectionText}
                    commentSectionPlaceholder={this.commentSectionPlaceholder}
                    thankYouModalText={this.thankYouModalText}
                    submitBtnText={this.submitBtnText}
                    closeBtnText={this.closeBtnText}
                    isEmojiSectionVisible={this.state.isEmojiSectionVisible}
                    isThankYouModalVisible={this.state.isThankYouModalVisible}
                    isCommentSectionVisible={this.state.isCommentSectionVisible}
                    setNewUserComment={this.setNewUserComment}
                    userComment={this.state.userComment}
                    emojies={this.state.emojies}
                    chosenRating={this.state.chosenRating}
                    setChosenRating={this.setChosenRating}
                    submitForm={this.submitForm}
                    isLoading={isLoading}
                    spaceInEmojiBlock={this.spaceInEmojiBlock}
                />
            );
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(meActions, dispatch)
        };
    }

    function mapStateToProps(state) {
        return {
            isLoading: isLoadingSendFeedbackOnHealthScore(state)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)((translate()(HealthScoreFeedbackModalBase)));
}

const MAX_MODAL_WIDTH = 300;

export const styles = {
    mainContainer: {
        borderRadius: spacing.s1,
        width: MAX_MODAL_WIDTH,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: baseColors.white
    },
    iconBlock: {
        backgroundColor: baseColors.primary,
        width: spacing.s15,
        height: spacing.s15,
        color: baseColors.white,
        borderRadius: spacing.s7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconBlockWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        marginBottom: spacing.s3,
        marginTop: -spacing.s7,
    },
    modalWrapper: {
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'flex-end'
    },
    modalText: {
        ...appFonts.smRegular,
        color: baseColors.grey20
    },
    emojiWrapper: {
        height: spacing.s9,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: spacing.s5
    },
    emojiBlock: {
        width: spacing.s11,
        height: spacing.s11,
        borderRadius: spacing.s5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    emoji: {
        width: spacing.s8,
        height: spacing.s8
    },
    spaceAroundCenterEmoji: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1
    },
    height24px: {
        height: spacing.s5
    },
    height36px: {
        height: spacing.s8
    },
    divider: {
        backgroundColor: '#D3D3D3',
        height: 1,
        marginTop: spacing.s5
    },
    footerButton: {
        ...appFonts.mdRegular,
        color: baseColors.secondary,
        padding: spacing.s3,
        textAlign: 'center',
    },
};
