import _ from 'lodash';

export { default as isEmail } from 'validator/lib/isEmail';
export { default as isNumeric } from 'validator/lib/isNumeric';

/* Allow user enter only int and float numbers */
export function isOnlyIntegerOrFloatNumbers(value) {
    return value && value.match(/^[0-9]{1,5}\.?[0-9]{0,4}$/gm);
}

/* Validation value of name input of entity when create new event/challenge/community  */
export function entityNameValidation(value) {
    const generalValidation = value.match(/^[A-Za-z0-9\s.+_&-]/gi);
    const chineseValidation = value.match(/^[\u4e00-\u9eff]/gi);
    const vietnameseValidation = value.match(/^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\W|_]+$/gi);
    const thaiValidation = value.match(/^[\u0E00-\u0E7F]/gi);
    return value && (generalValidation || chineseValidation || vietnameseValidation || thaiValidation);
}

/* Check if value contain one or more emoji */
export function isEmoji(value) {
    return value && value.match(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g);
}

export function isStringIncludeHTML(str) {
    return str && str.match(/(<([^>]+)>)/gim);
}

export function hasUrlInStr(str) {
    const regex = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gi;
    return str && str.match(regex);
}

export function isTextNaN(text) {
    return _.isNaN(_.toNumber(text));
}

export function removeEmojiFromText(text) {
    return text.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi, '');
}

export function isValidEmailAddress(text) {
    return text && text.match(/(^[a-zA-Z0-9]+([._+-][0-9a-zA-Z]+)*@[a-zA-Z0-9]+([.-][0-9a-zA-Z]+)*\.[a-zA-Z]{2,}$)/g);
}